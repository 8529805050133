// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your Firebase configuration object
const firebaseConfig = {

  apiKey: "AIzaSyC7PlMLKTDiK1aG3gNlgWRQ20tdWNCl_VM",

  authDomain: "truemetric-cffb7.firebaseapp.com",

  projectId: "truemetric-cffb7",

  storageBucket: "truemetric-cffb7.appspot.com",

  messagingSenderId: "30715104344",

  appId: "1:30715104344:web:adbc2077f383ae7129ce2d",

  measurementId: "G-6CWSBQ378G"

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
