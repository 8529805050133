// src/components/ApologyPage.js
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, TextField, Button, Box, Alert } from '@mui/material';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const ApologyPage = () => {
  const location = useLocation();
  const { apologyMessage } = location.state || {};
  const [feedback, setFeedback] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [requestContact, setRequestContact] = useState(false);
  
  // State variables for contact information
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  // State variables for validation errors
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (feedback.trim() === '') return;

    // Reset previous errors
    setSubmitError(null);

    // Basic email validation if email is provided
    if (email.trim() !== '') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email.trim())) {
        setEmailError(true);
        return;
      } else {
        setEmailError(false);
      }
    }

    // Basic phone validation if phone is provided (e.g., only numbers and optional +)
    if (phone.trim() !== '') {
      const phoneRegex = /^\+?\d{7,15}$/;
      if (!phoneRegex.test(phone.trim())) {
        setPhoneError(true);
        return;
      } else {
        setPhoneError(false);
      }
    }

    setIsSubmitting(true);

    try {
      // Prepare the feedback data
      const feedbackData = {
        feedback,
        timestamp: new Date(),
      };

      // If user requested contact, include additional fields
      if (requestContact) {
        feedbackData.name = name.trim() !== '' ? name.trim() : null;
        feedbackData.phone = phone.trim() !== '' ? phone.trim() : null;
        feedbackData.email = email.trim() !== '' ? email.trim() : null;
      }

      await addDoc(collection(db, 'feedback'), feedbackData);
      setSubmitted(true);

      // Optionally, clear the form fields
      setFeedback('');
      setName('');
      setPhone('');
      setEmail('');
      setRequestContact(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      setSubmitError("There was an error submitting your feedback. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container sx={{ textAlign: 'center', marginTop: '50px', maxWidth: '600px' }}>
      <Typography variant="h5" gutterBottom>
        {apologyMessage || "We're sorry to hear that."}
      </Typography>
      {!submitted ? (
        <Box 
          sx={{ 
            marginTop: '30px', 
            display: 'flex', 
            flexDirection: 'column', 
            gap: '20px' 
          }}
        >
          {/* Feedback Section */}
          <TextField
            label="Your Feedback"
            multiline
            rows={4}
            variant="outlined"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            fullWidth
            required
          />
          
          {/* Error Alert */}
          {submitError && (
            <Alert severity="error">{submitError}</Alert>
          )}

          {/* Request Contact Button */}
          <Button 
            variant="text" 
            color="secondary" 
            onClick={() => setRequestContact(!requestContact)}
            size="small"
          >
            {requestContact ? 'Cancel Contact Request' : 'Request Contact'}
          </Button>

          {/* Contact Information Fields */}
          {requestContact && (
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: '20px', 
              textAlign: 'left' 
            }}>
              <TextField
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
              <TextField
                label="Phone"
                variant="outlined"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                fullWidth
                error={phoneError}
                helperText={phoneError ? "Please enter a valid phone number." : ""}
              />
              <TextField
                label="Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                error={emailError}
                helperText={emailError ? "Please enter a valid email address." : ""}
              />
            </Box>
          )}

          {/* Submit Feedback Button */}
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSubmit}
            disabled={feedback.trim() === '' || isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit Feedback'}
          </Button>
        </Box>
      ) : (
        <Typography variant="h6" sx={{ marginTop: '30px' }}>
          Thank you for your feedback!
        </Typography>
      )}
    </Container>
  );
};

export default ApologyPage;
