// src/components/HomePage.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase';
import {
  doc,
  getDoc,
  updateDoc,
  increment,
  serverTimestamp,
  collection,
  addDoc,
  runTransaction,
  collectionGroup,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import {
  Container,
  Typography,
  Button,
  Box,
  CircularProgress,
  Alert,
} from '@mui/material';
import { motion } from 'framer-motion';
import NoTagHomePage from './NoTagHomePage'; // Import the new component

const HomePage = () => {
  const [clientData, setClientData] = useState(null);
  const [platformData, setPlatformData] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showNoTagHomePage, setShowNoTagHomePage] = useState(false); // New state variable
  const navigate = useNavigate();
  const location = useLocation();

  // Extract tagId from query params
  const queryParams = new URLSearchParams(location.search);
  const tagId = queryParams.get('tagId');

  // useRef to prevent duplicate hits
  const hitLogged = useRef(false);

  useEffect(() => {
    if (!tagId) {
      setShowNoTagHomePage(true); // Set to true to show NoTagHomePage
      setLoading(false);
      return;
    }

    if (hitLogged.current) {
      setLoading(false);
      return;
    }

    const logHit = async () => {
      try {
        // Perform a collection group query to find the tagId
        const nfcTagsQuery = query(
          collectionGroup(db, 'nfcTags'),
          where('tagId', '==', tagId)
        );
        const querySnapshot = await getDocs(nfcTagsQuery);

        if (querySnapshot.empty) {
          throw new Error('Tag ID not found.');
        }

        // Assuming tagId is unique across all nfcTags subcollections
        const nfcTagDoc = querySnapshot.docs[0];
        const nfcTagData = nfcTagDoc.data();

        // Extract clientId from the document's path
        const clientDocRef = nfcTagDoc.ref.parent.parent;
        const clientId = clientDocRef.id;
        setClientId(clientId);

        // Fetch client data
        const clientDocSnap = await getDoc(clientDocRef);
        if (!clientDocSnap.exists()) {
          throw new Error('Client data not found.');
        }
        const clientDataFetched = clientDocSnap.data();
        setClientData(clientDataFetched);

        // Fetch platform data based on client's reviewPlatform
        const platformDocRef = doc(
          db,
          'platforms',
          clientDataFetched.reviewPlatform.toLowerCase()
        );
        const platformDocSnap = await getDoc(platformDocRef);
        if (!platformDocSnap.exists()) {
          throw new Error('Platform data not found.');
        }
        setPlatformData(platformDocSnap.data());

        // Use a transaction to update the nfcTag document and log the hit
        await runTransaction(db, async (transaction) => {
          // Update totalHits and lastHit
          transaction.update(nfcTagDoc.ref, {
            totalHits: increment(1),
            lastHit: serverTimestamp(),
          });

          // Log the hit in tagUsage collection
          const tagUsageCollectionRef = collection(db, 'tagUsage');
          const tagUsageDocRef = doc(tagUsageCollectionRef);
          transaction.set(tagUsageDocRef, {
            clientId,
            nfcTagId: tagId,
            action: 'hit',
            timestamp: serverTimestamp(),
          });
        });

        // Mark hit as logged to prevent duplicates
        hitLogged.current = true;
      } catch (error) {
        console.error('Error logging hit:', error);
        setErrorMsg('An error occurred while logging your visit.');
      } finally {
        setLoading(false);
      }
    };

    logHit();
  }, [tagId]);

  const handleResponse = async (response) => {
    if (!clientData || !tagId || !clientId) return;

    try {
      if (response === 'yes' && platformData) {
        const reviewUrl = `${platformData.baseUrl}${clientData.platformId}`;
        navigate('/thank-you', { state: { reviewUrl, clientId, tagId } });

        // Reference to the specific NFC tag document
        const nfcTagDocRef = doc(db, 'clients', clientId, 'nfcTags', tagId);
        await updateDoc(nfcTagDocRef, {
          totalYes: increment(1),
        });

        // Log the 'yes' in tagUsage collection
        await addDoc(collection(db, 'tagUsage'), {
          clientId,
          nfcTagId: tagId,
          action: 'yes',
          timestamp: serverTimestamp(),
        });
      } else if (response === 'no') {
        navigate('/apology', {
          state: { apologyMessage: clientData.apologyMessage, clientId, tagId },
        });

        // Log the 'no' in tagUsage collection
        await addDoc(collection(db, 'tagUsage'), {
          clientId,
          nfcTagId: tagId,
          action: 'no',
          timestamp: serverTimestamp(),
        });
      }
    } catch (error) {
      console.error('Error handling response:', error);
      setErrorMsg('An error occurred while processing your response.');
    }
  };

  if (loading) {
    return (
      <Container
        sx={{
          textAlign: 'center',
          marginTop: '50px',
          minHeight: '100vh', // Ensure the loader is centered vertically
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Container>
    );
  }

  if (showNoTagHomePage) {
    return <NoTagHomePage />;
  }

  if (errorMsg) {
    return (
      <Container
        sx={{
          textAlign: 'center',
          marginTop: '50px',
          padding: '0 20px', // Prevent text from touching the edges
        }}
      >
        <Alert severity="error">{errorMsg}</Alert>
      </Container>
    );
  }

  return (
    <Container
      sx={{
        textAlign: 'center',
        marginTop: '50px',
        maxWidth: '800px',
        padding: { xs: '20px', sm: '40px' },
        boxShadow: 3,
        borderRadius: 4,
        backgroundColor: '#ffffff',
        overflowX: 'hidden', // Prevent horizontal overflow
      }}
    >
      {/* Logo or Display Name */}
      <Box sx={{ marginBottom: '40px' }}>
        {clientData.logoUrl ? (
          <motion.img
            src={clientData.logoUrl}
            alt={`${clientData.displayName} Logo`}
            style={{
              maxWidth: '150px', // Reduced for better responsiveness
              height: 'auto',
              borderRadius: '50%',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              objectFit: 'cover', // Ensure image fits within the container
              margin: '0 auto', // Center the image
            }}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            onError={(e) => {
              e.target.onerror = null; // Prevent infinite loop if fallback also fails
              e.target.src = ''; // Remove broken image
            }}
          />
        ) : (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: 'bold',
                background: 'linear-gradient(90deg, #FF8A00, #E52E71)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontFamily: 'Montserrat, sans-serif',
                textTransform: 'uppercase',
                letterSpacing: '2px',
                fontSize: { xs: '2rem', sm: '3rem' }, // Responsive font size
                wordBreak: 'break-word', // Prevent text overflow
              }}
            >
              {clientData.displayName}
            </Typography>
          </motion.div>
        )}
      </Box>

      {/* Main Content */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {clientData.reviewPlatform === 'Sonic' ? (
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: '500',
              color: '#333333',
              lineHeight: 1.6,
              fontSize: { xs: '1.2rem', sm: '1.5rem' }, // Responsive font size
              paddingX: { xs: '10px', sm: '20px' }, // Padding to prevent text touching edges
              wordBreak: 'break-word', // Ensure text wraps properly
            }}
          >
            Would you say you were{' '}
            <motion.span
              initial={{ backgroundPosition: '0% 50%' }}
              animate={{ backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'] }}
              transition={{ duration: 5, repeat: Infinity }}
              style={{
                background: 'linear-gradient(270deg, #FF8A00, #E52E71)',
                backgroundSize: '200% 200%',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold',
                display: 'inline-block',
              }}
            >
              VERY SATISFIED
            </motion.span>{' '}
            with your overall experience at Sonic today?
          </Typography>
        ) : (
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: '500',
              color: '#333333',
              lineHeight: 1.6,
              fontSize: { xs: '1.2rem', sm: '1.5rem' }, // Responsive font size
              paddingX: { xs: '10px', sm: '20px' }, // Padding to prevent text touching edges
              wordBreak: 'break-word', // Ensure text wraps properly
            }}
          >
            Would you be willing to leave us a {clientData.starRating}{' '}
            {clientData.reviewPlatform} review today?
          </Typography>
        )}
      </motion.div>

      {/* Buttons */}
      <Box
        sx={{
          marginTop: '50px',
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
          flexDirection: { xs: 'column', sm: 'row' }, // Stack vertically on small screens
          alignItems: 'center', // Center align items
        }}
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5, type: 'spring', stiffness: 100 }}
          style={{ width: '100%', maxWidth: '200px' }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              padding: '12px 24px',
              borderRadius: '30px',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
              fontSize: { xs: '1rem', sm: '1.1rem' }, // Responsive font size
              textTransform: 'none',
              transition: 'transform 0.2s, box-shadow 0.2s',
              width: '100%', // Ensure button takes full width on small screens
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)',
              },
            }}
            onClick={() => handleResponse('yes')}
          >
            Yes
          </Button>
        </motion.div>

        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.6, type: 'spring', stiffness: 100 }}
          style={{ width: '100%', maxWidth: '200px' }}
        >
          <Button
            variant="outlined"
            color="secondary"
            size="large"
            sx={{
              padding: '12px 24px',
              borderRadius: '30px',
              border: '2px solid #E52E71',
              fontSize: { xs: '1rem', sm: '1.1rem' }, // Responsive font size
              textTransform: 'none',
              transition:
                'transform 0.2s, box-shadow 0.2s, background-color 0.2s',
              width: '100%', // Ensure button takes full width on small screens
              '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.3)',
                backgroundColor: '#E52E71',
                color: '#ffffff',
              },
            }}
            onClick={() => handleResponse('no')}
          >
            No
          </Button>
        </motion.div>
      </Box>
    </Container>
  );
};

export default HomePage;
