// src/components/NoTagHomePage.js
import React from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
// Removed MUI Material Icons import
// import { Menu as MenuIcon } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useTypewriter, Cursor } from 'react-simple-typewriter';
import { Parallax } from 'react-parallax';
// Import react-icons for icons
import { FaChartLine, FaLock, FaCogs, FaBars } from 'react-icons/fa';
// Import react-slick components
import Slider from 'react-slick';
// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NoTagHomePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [text] = useTypewriter({
    words: ['Accurate Metrics.', 'Insightful Analytics.', 'Empower Your Business.', 'Grow Your Reviews.'],
    loop: {},
    typeSpeed: 50,
    deleteSpeed: 30,
    delaySpeed: 2000,
  });

  const handleScrollTo = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  // Settings for react-slick
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
  };

  return (
    <Box sx={{ backgroundColor: '#0e0e0e', overflowX: 'hidden' }}>
      {/* Navigation Bar */}
      <AppBar position="fixed" sx={{ background: 'transparent', boxShadow: 'none' }}>
        <Toolbar sx={{ justifyContent: 'flex-end' }}>
          {!isMobile && (
            <Box>
              <Button color="inherit" onClick={() => handleScrollTo('features')}>
                Features
              </Button>
              <Button color="inherit" onClick={() => handleScrollTo('testimonials')}>
                Testimonials
              </Button>
              <Button color="inherit" onClick={() => navigate('/contact')}>
                Contact
              </Button>
            </Box>
          )}
          {isMobile && (
            <IconButton edge="end" color="inherit" onClick={() => navigate('/menu')}>
              <FaBars size={24} /> {/* Replaced MenuIcon with FaBars */}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Hero Section */}
      <Parallax bgImage="/images/background.jpg" strength={500}>
        <Box
          sx={{
            minHeight: '100vh',
            position: 'relative',
            color: '#ffffff',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start', // Adjusted justifyContent
            paddingTop: '70px',
          }}
        >
          {/* Particle Effect */}
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              background: 'rgba(0,0,0,0.6)',
              zIndex: 1,
            }}
          >
            {/* You can integrate a particle effect library here */}
          </Box>

          <Container
            sx={{
              position: 'relative',
              zIndex: 2,
              marginTop: '0px',
            }}
          >
            {/* Logo */}
            <motion.img
              src="/images/logo.png"
              alt="TrueMetric Logo"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              style={{
                width: '200px',
                height: 'auto',
                marginBottom: '50px',
                filter: 'drop-shadow(0 0 0.25px rgba(255, 255, 255, 0.5))',
                cursor: 'pointer',
              }}
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            />

            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
            >
              <Typography
                variant={isMobile ? 'h3' : 'h2'}
                sx={{
                  fontWeight: 'bold',
                  fontFamily: 'Montserrat, sans-serif',
                  textTransform: 'uppercase',
                  letterSpacing: '4px',
                  marginBottom: '20px',
                  color: '#ffffff',
                  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                }}
              >
                Welcome to TrueMetric.io
              </Typography>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: '500',
                  fontFamily: 'Roboto, sans-serif',
                  marginBottom: '40px',
                  color: '#ffffff',
                  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
                }}
              >
                <span>
                  {text}
                  <Cursor />
                </span>
              </Typography>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 1, duration: 0.5 }}
            >
              <Button
                variant="contained"
                size="large"
                sx={{
                  padding: '15px 30px',
                  borderRadius: '30px',
                  fontSize: '1.2rem',
                  textTransform: 'none',
                  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)',
                  background: 'linear-gradient(90deg, #FF8A00, #E52E71)',
                  color: '#ffffff',
                  '&:hover': {
                    background: 'linear-gradient(90deg, #E52E71, #FF8A00)',
                  },
                }}
                onClick={() => navigate('/contact')}
              >
                Get Started
              </Button>
            </motion.div>
          </Container>
        </Box>
      </Parallax>

      {/* Features Section */}
      <Box
        id="features"
        sx={{
          backgroundColor: '#ffffff',
          color: '#333333',
          paddingY: '80px',
        }}
      >
        <Container>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              marginBottom: '40px',
              textAlign: 'center',
            }}
          >
            Why Choose TrueMetric.io?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-around',
              alignItems: 'center',
              gap: '40px',
            }}
          >
            {/* Feature 1 */}
            <Box sx={{ maxWidth: '300px', textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
              >
                <FaChartLine size={60} color="#FF8A00" style={{ marginBottom: '20px' }} />
                <Typography variant="h6" sx={{ fontWeight: '500' }}>
                  Real-Time Analytics
                </Typography>
                <Typography variant="body1">
                  Gain insights with up-to-the-minute data analytics.
                </Typography>
              </motion.div>
            </Box>
            {/* Feature 2 */}
            <Box sx={{ maxWidth: '300px', textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <FaLock size={60} color="#E52E71" style={{ marginBottom: '20px' }} />
                <Typography variant="h6" sx={{ fontWeight: '500' }}>
                  Secure & Reliable
                </Typography>
                <Typography variant="body1">
                  Trust in our top-tier security and uptime.
                </Typography>
              </motion.div>
            </Box>
            {/* Feature 3 */}
            <Box sx={{ maxWidth: '300px', textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <FaCogs size={60} color="#FF8A00" style={{ marginBottom: '20px' }} />
                <Typography variant="h6" sx={{ fontWeight: '500' }}>
                  Custom Solutions
                </Typography>
                <Typography variant="body1">
                  Tailor-made analytics to fit your business needs.
                </Typography>
              </motion.div>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Box
        id="testimonials"
        sx={{
          backgroundColor: '#f5f5f5',
          paddingY: '80px',
        }}
      >
        <Container>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              marginBottom: '40px',
              textAlign: 'center',
            }}
          >
            What Our Clients Say
          </Typography>
          <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
            <Slider {...sliderSettings}>
              <Box
                sx={{
                  textAlign: 'center',
                  paddingX: '20px',
                }}
              >
                <Typography variant="body1" sx={{ fontStyle: 'italic', marginBottom: '20px' }}>
                  "TrueMetric.io has revolutionized the way we look at data. Their insights have
                  empowered us to make informed decisions."
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: '500' }}>
                  - Alex Johnson, CEO of TechCorp
                </Typography>
              </Box>
              <Box
                sx={{
                  textAlign: 'center',
                  paddingX: '20px',
                }}
              >
                <Typography variant="body1" sx={{ fontStyle: 'italic', marginBottom: '20px' }}>
                  "The real-time analytics provided by TrueMetric.io are unmatched. Our productivity
                  has soared."
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: '500' }}>
                  - Maria Rodriguez, COO of FinSolutions
                </Typography>
              </Box>
              {/* Add more slides as needed */}
            </Slider>
          </Box>
        </Container>
      </Box>

      {/* Call to Action Section */}
      <Box
        sx={{
          backgroundColor: '#0e0e0e',
          color: '#ffffff',
          paddingY: '80px',
          textAlign: 'center',
        }}
      >
        <Container>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              marginBottom: '20px',
            }}
          >
            Ready to Elevate Your Business?
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '40px' }}>
            Contact us today to get started with TrueMetric.io.
          </Typography>
          <Button
            variant="contained"
            size="large"
            sx={{
              padding: '15px 30px',
              borderRadius: '30px',
              fontSize: '1.2rem',
              textTransform: 'none',
              background: 'linear-gradient(90deg, #E52E71, #FF8A00)',
              color: '#ffffff',
              '&:hover': {
                background: 'linear-gradient(90deg, #FF8A00, #E52E71)',
              },
            }}
            onClick={() => navigate('/contact')}
          >
            Get in Touch
          </Button>
        </Container>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          backgroundColor: '#0a0a0a',
          color: '#777777',
          paddingY: '20px',
          textAlign: 'center',
        }}
      >
        <Typography variant="body2">
          © {new Date().getFullYear()} TrueMetric.io. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default NoTagHomePage;
