// src/components/ThankYouPage.js
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, CircularProgress, Alert } from '@mui/material';
import { collection, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

const ThankYouPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { reviewUrl, clientId } = location.state || {};
  
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const hasHandled = useRef(false); // Reference flag to prevent multiple executions

  // Helper function to generate a unique token ID
  const generateTokenId = () => {
    const timestamp = Date.now().toString(36); // Convert timestamp to base36
    const randomNum = Math.random().toString(36).substring(2, 8); // Generate a random 6-character string
    return `${timestamp}-${randomNum}`;
  };

  useEffect(() => {
    const handleToken = async () => {
      if (hasHandled.current) {
        // If already handled, do nothing
        return;
      }

      hasHandled.current = true; // Set the flag to true to prevent future executions

      try {
        if (!clientId) {
          throw new Error('Client ID is missing.');
        }

        // Check if a token already exists in local storage
        const existingToken = localStorage.getItem('accessToken');

        if (existingToken) {
          // Reference to the existing token document
          const existingTokenDocRef = doc(db, 'tokens', existingToken);
          const existingTokenSnap = await getDoc(existingTokenDocRef);

          if (existingTokenSnap.exists()) {
            // Token exists in Firestore, proceed to redirect
            navigateToReview();
          } else {
            // Token does not exist in Firestore, generate a new one
            await generateAndStoreToken();
          }
        } else {
          // No existing token, generate a new one
          await generateAndStoreToken();
        }
      } catch (err) {
        console.error('Error handling token:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const generateAndStoreToken = async () => {
      // Generate a new token ID
      const newTokenId = generateTokenId();

      // Reference to the new token document
      const tokenDocRef = doc(collection(db, 'tokens'), newTokenId);

      // Token data
      const tokenData = {
        tokenId: newTokenId,
        clientId: clientId,
        used: false,
        timestamp: new Date(),
      };

      // Store the token in Firestore
      await setDoc(tokenDocRef, tokenData);

      // Store the token ID in local storage
      localStorage.setItem('accessToken', newTokenId);

      // Proceed to redirect
      navigateToReview();
    };

    const navigateToReview = () => {
      if (reviewUrl) {
        window.location.href = reviewUrl;
      } else {
        setError('Review URL is missing.');
      }
    };

    handleToken();
  }, [clientId, reviewUrl, navigate]);

  if (loading) {
    return (
      <Container sx={{ textAlign: 'center', marginTop: '100px' }}>
        <Typography variant="h5" gutterBottom>
          Thank you! Redirecting you to our review page...
        </Typography>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container sx={{ textAlign: 'center', marginTop: '100px' }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  // In case neither loading nor error is present, render nothing
  return null;
};

export default ThankYouPage;
